import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'codeCombination',
  components: {},
  watch: {
    batchCurrentPage() {
      this.getLocCodeCombGridData();
    },
    batchPerPage() {
      this.currentPage = 1;
      this.getLocCodeCombGridData();
    }
  },
  data() {
    return {
      loader: false,
      concatSeg: null,
      active: true,
      locCodeCombData: [],
      locCodeCombFields: [
        {
          key: 'fa_locator_ccid',
          label: 'FA Locator CCID'
        },
        {
          key: 'concatenated_segment'
        },
        {
          key: 'locator'
        },
        {
          key: 'rack'
        },
        {
          key: 'shelf'
        },
        {
          key: 'bin'
        },
        // {
        //   key: 'segment5',
        //   label: 'Segment 5'
        // },
        // {
        //   key: 'segment6',
        //   label: 'Segment 6'
        // },
        // {
        //   key: 'segment7',
        //   label: 'Segment 7'
        // },
        // {
        //   key: 'segment8',
        //   label: 'Segment 8'
        // },
        // {
        //   key: 'segment9',
        //   label: 'Segment 9'
        // },
        // {
        //   key: 'segment10',
        //   label: 'Segment 10'
        // },
        // {
        //   key: 'active'
        // },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_updated_by'
        },
        {
          key: 'last_update_date' 
        }
      ],
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1
    };
  },
  validations: {},
  mounted() {
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'shared/setActionName') {
    //     const actionName = state.shared.actionName;
    //     if (actionName === 'upload') {
    //       this.showExcelUploadModal = true;
    //     }
    //   }
    // });
    this.getLocCodeCombGridData();
  },
  methods: {
    // rowSelected(item) {
    //   const active = item.active ? 'Yes' : !item.active ? 'No' : null;
    //   const result = item.concatenated_segment + active;
    //   this.$emit('closeModal', result);
    // },
    getLocCodeCombGridData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        // fa_locator_ccid: this.fa,
        // fa_location_type: this.fa,
        concatenated_segment: this.concatSeg,
        active: this.active
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getLocCodeCombGridData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.locCodeCombData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => (this.loader = false));
    },
    clear() {
      this.concatSeg = null;
      this.locCodeCombData = [];
    }
  }
};